body {
    .buttons {
        margin-top: 20px;
    } 


    .radio-inline {
        padding-top: 7px;

    }

    .radio-inline+.radio-inline {
        margin-left: 10px;
    }
}



.form-horizontal {
    .form-group { 
        margin-right: -15px;
        margin-left: -15px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        label {
            display: inline-block;
        }

        .control-label {
            @media (min-width: 767px) {
                padding-top: 7px;
                margin-bottom: 0;
                text-align: right;
            }

        }
    }
}

.information-contact{
    .form-horizontal {
        .form-group { 
            display: block;
            margin-left: 0;
            margin-right: 0;
        }
    }

}